<template>
  <div class="warp">
    <van-form @submit="onSubmit">
      <van-field
        label-width="100"
        v-model="query.username"
        name="姓名"
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        type="tel"
        label-width="100"
        v-model="query.userMobile"
        name="手机号码"
        label="手机号码"
        placeholder="请输入手机号码"
        :rules="[{ pattern: /^(0|86|17951)?1[\d]{10}$/, message: '请输入正确的手机号' }]" />
      <van-field
        label-width="100"
        v-model="query.userEmail"
        name="电子邮件"
        label="电子邮件"
        placeholder="请输入电子邮件"
        :rules="[{ required: true, message: '请填写电子邮件' }]"
      />
      <van-field
        label-width="100"
        v-model="query.remarks"
        rows="2"
        autosize
        label="留言"
        type="textarea"
        maxlength="50"
        placeholder="请输入留言"
        show-word-limit
      />
      <div class="btn">
        <van-button v-if="isShowbtn" round block type="info" native-type="submit">提交</van-button>
        <van-button v-else loading  type="primary" disabled block loading-text="请稍等..." />
      </div>
    </van-form>
  </div>
</template>

<script>
import { useradd, getInfo, companyInfo, jsapisignature } from '@/api/notice.js'
import { Toast } from 'vant'
export default {
  data () {
    return {
      isShowbtn: true,
      query: {
        companyId: undefined, // 企业id(从url获取)
        username: undefined, // 姓名
        userMobile: undefined, // 手机号码
        userEmail: undefined, // 电子邮箱
        remarks	: undefined, // 备注
      },
      comName: ''
    }
  },
  created() {
    this.query.companyId = this.$route.query.companyId
    this.companyInfo()
  },
  methods: {
    companyInfo() {
      companyInfo().then(res => {
        if (res.data.code === 200) {
          this.comName = res.data.data.info.comName
          this.getInfo()
        }
      })
    },
    getInfo() {
      getInfo().then(res => {
        if (res.data.code === 200 && res.data.data.hsCompanyUser) {
          const isPass = res.data.data.hsCompanyUser.isPass
          if (isPass === 1) {
            this.$router.push({path: 'Errorpage', query: {msg: '对不起，您的微信名下已经绑定【'+ this.comName + '】'}})
          } else if (isPass === 2) {
            Toast('您的审核未通过，请重新申请')
          } else if (isPass === 0) {
            this.$router.push({path: 'Errorpage', query: {msg: '您的申请正在审核中'}})
          }
        }
      })
    },
    jsapisignature() {
      let hrefurl = sessionStorage.getItem("url")
      jsapiSignature({url: hrefurl}).then(res => {
        setTimeout(function () {
          wx.config({
            debug: false,
            appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: ['closeWindow']
          })
          wx.ready(function () {
            wx.closeWindow()
          })
        }, 2000)
      })
    },
    onSubmit(values) {
      this.isShowbtn = false
      useradd(this.query).then(res => {
        if (res.data.code === 200) {
          Toast('提交成功')
          this.jsapisignature() // 关闭窗口
        }
      })
    },
  }
}

</script>
<style lang="scss" scoped>
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
</style>
